package com.ahmedadeltito.website.sections

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.components.SectionTitle
import com.ahmedadeltito.website.components.WorkCard
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Work
import com.ahmedadeltito.website.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun WorkSection() {
    Box(
        modifier = Modifier
            .id(Section.Work.id)
            .fillMaxWidth()
            .maxWidth(size = SECTION_WIDTH.px)
            .padding(top = 80.px, bottom = 20.px)
            .classNames("section-container"),
        contentAlignment = Alignment.Center
    ) {
        WorkContent()
    }
}

@Composable
fun WorkContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxWidth(percent = if (breakpoint >= Breakpoint.XL) 80.percent else 100.percent),
        verticalArrangement = Arrangement.Center
    ) {
        SectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = if (breakpoint >= Breakpoint.XL) 20.px else 40.px),
            section = Section.Work,
            alignment = Alignment.CenterHorizontally
        )
        SimpleGrid(
            modifier = Modifier.padding(
                topBottom = 60.px,
                leftRight = if (breakpoint >= Breakpoint.XL) 60.px else 20.px
            ),
            numColumns = numColumns(base = 1, md = 2)
        ) {
            Work.entries.forEach { work ->
                Box(modifier = Modifier.margin(all = 10.px)) {
                    WorkCard(work = work)
                }
            }
        }
    }
}